// ---------------------------
var $ = jQuery;
$(document).ready(function () {
  // $('iframe').each(function(){
  //   function injectCSS(){
  //     $iframe.contents().find('head').append(
  //       $('<link/>', { rel: 'stylesheet', href: '../assets/css/styles.min.css', type: 'text/css' })
  //     );
  //   }
  //
  //   var $iframe = $(this);
  //   $iframe.on('load', injectCSS);
  //   injectCSS();
  // });
  $('a[href^="#"]').click(function() {
    var hrefid = $(this).attr("href");
    var header = $('#js-header').outerHeight();
      $('html, body').animate({
        scrollTop: $(hrefid).offset().top - header
      }, 500);
    return false;
  });

  /* AnimeScroll
  =========================================== */

  jQuery.easing.quart = function(x, t, b, c, d){
  	return -c * ((t=t/d-1)*t*t*t - 1) + b;
  };

  var aniscroll = {
  	setInit : function(){
  		$('a[href*=#],area[href*=#]').click(function(){
  			if(location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname){
  				var $target = $(this.hash);
  				$target = $target.length && $target || $('[name='+this.hash.slice(1)+']');
  				if($target.length){
  					var targetOffset = $target.offset().top;
  					var targetTag = navigator.appName.match(/Opera/)? "html" : "html,body";
  					$(targetTag).animate({scrollTop: targetOffset}, 'quart');
  					return false;
  				}
  			}
  		});
  	}
  }

  //-----------------------Accordions-------------------------------------------

  $(".accordions").on("click", ".accordions_title", function () {
    $(this).toggleClass("active").next().slideToggle();
  });

  jQuery(function ($) {
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $('ul a').each(function () {
      if (this.href === path) {
        $(this).addClass('active');

      }
    });
  });

  // menu----------------------------------

  $(".menu-header-mobile .has-nav-child > a").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).hasClass("is-active")) {
      $(".menu-header-mobile .has-nav-child > a").removeClass("is-active");
      $(this).next().removeClass("is-active").slideUp(300);
    } else {

      $(".menu-header-mobile .has-nav-child > a").removeClass("is-active");
      $(".menu-header-mobile .has-nav-child > a").next().slideUp(300);
      $(this).addClass("is-active");
      $(this).next().addClass("is-active").slideDown(300);
    }
  })

  $(".btn-nav").on("click", function (e) {
    e.preventDefault();
    $('.menu-header-mobile').toggleClass('active');
    $(".dropdown").removeClass("active");
    $(".btn_dropdown").removeClass("is-active");
    // $('.toggle-right li').toggleClass('show-right');
  });

  $(".btn-nav").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).hasClass("is-active")) {
      $(".btn-nav").removeClass("is-active");
    } else {
      $(".btn-nav").removeClass("is-active");
      $(this).addClass("is-active");
    }

  });
  $('.btn-nav').click(function () {
    if ($(this).hasClass("is-active")) {
      $('html').addClass('overflow');
    } else {
      $('html').removeClass('overflow');
    }
  });
  // dropdown
  $(".btn_dropdown").on("click", function (e) {
    e.preventDefault();
    $('.dropdown').toggleClass('active');
    $(".menu-header-mobile").removeClass("active");
    $(".btn-nav").removeClass("is-active");
    // $('.toggle-right li').toggleClass('show-right');
  });

  $(".btn_dropdown").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).hasClass("is-active")) {
      $(".btn_dropdown").removeClass("is-active");
    } else {
      $(".btn_dropdown").removeClass("is-active");
      $(this).addClass("is-active");
    }

  });
  $('.btn_dropdown').click(function () {
    if ($(this).hasClass("is-active")) {
      $('html').addClass('overflow');
    } else {
      $('html').removeClass('overflow');
    }
  });

  // tab
  $(".tab-content .tab:first").show();
  $(".tab-control .button").click(function () {
    $(".tab-control .button").removeClass("active");
    $(this).addClass("active");
    $(".tab-content .tab").hide();
    var url = $(this).attr("data-tab");
    $("#" + url).fadeIn();
  });
  $("#tab0001").click(function () {
    $(".dropdown").removeClass("active");
    $(".button_01").triggerHandler("click");
    $("#tab0002").removeClass("active");
    $(".btn_dropdown").removeClass("is-active");
    $('html').removeClass('overflow');

    $(this).addClass("active");
    $('html, body').animate({
      scrollTop: $(".tab-control").offset().top
    }, 100);
  });
  $("#tab0002").click(function () {
    $(".dropdown").removeClass("active");
    $(".button_02").triggerHandler("click");
    $("#tab0001").removeClass("active");
    $(".btn_dropdown").removeClass("is-active");
    $('html').removeClass('overflow');

    $(this).addClass("active");
    $('html, body').animate({
      scrollTop: $(".tab-control").offset().top
    }, 100);
  });



  // dropdown
  $('.mail-header').click(function () {
    $(this).toggleClass('show');
  });

  // accordion
  var acc = document.getElementsByClassName("ques");
  var i;
  // $('.answ').hide();
  // $("ul .answ:first").show();
  // $(".ques1").addClass("active");
  // $(".ques1").click(function() {
  // $(this).toggleClass("active");
  // });

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = $(this).next();
      panel.slideToggle();
    });
  }

  // $('#cb1').click(function(){
  var checknum1 = 0;
  var checknum2 = 0;

  $('#cb1').change(function () {
    if ($(this).prop("checked") == true) {
      checknum1 = 1;
      if (checknum2 == 1) {
        $(".submit").addClass("active");
      }
    } else if ($(this).prop("checked") == false) {
      checknum1 = 0;
      $(".submit").removeClass("active");
    }

  });
  $('#cb2').change(function () {
    if ($(this).prop("checked") == true) {
      checknum2 = 1;
      if (checknum1 == 1) {
        $(".submit").addClass("active");
      }
    } else if ($(this).prop("checked") == false) {
      checknum2 = 0;
      $(".submit").removeClass("active");
    }
  });
  // if (checknum1 == 1 & checknum2 == 1) {
  //   $(".submit").addClass("active");
  // }
  // else {
  //   $(".submit").removeClass("active");
  // }

  // scroll faq
  // -----------------------------
  // Cache selectors
  var lastId,
    topMenu = $("#left_side_fix"),
    topMenuHeight = 0,
    // All list items
    menuItems = topMenu.find("a"),
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"));
      if (item.length) {
        return item;
      }
    });

  // Bind click handler to menu items
  // so we can get a fancy scroll animation
  menuItems.click(function (e) {
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 300);
    e.preventDefault();
  });

  // Bind to scroll
  $(window).scroll(function () {
    // Get container scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // Get id of current scroll item
    var cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop)
        return this;
    });
    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
      lastId = id;
      // Set/remove active class
      menuItems
        .parent().removeClass("active")
        .end().filter("[href='#" + id + "']").parent().addClass("active");
    }
  });
});
jQuery(document).ready(function ($) {

  if (performance.navigation.type == 2) {
    location.reload(true);
  };

});

// $(document).ready(function(){
// 	$(window).on('load', function () {
// 		var viewWidth = $(window).width();
// 		if(viewWidth > 768){
// 			$('.faq_content ul').show();
// 			$(".faq_content .anchor_tt").removeClass("anchor_tt_sp");
//
// 		}else {
// 			$(".faq_content .anchor_tt").addClass("anchor_tt_sp");
//       $('.faq_content ul').hide();
//   		$(".faq_content ul:first").show();
// 		}
//
// 		var speed = 'fast';
//
// 		$('.faq_content .anchor_tt_sp').click(function(){
// 			if(!$(this).hasClass('active')){
// 				$(this).addClass('active').next().slideDown(speed);
// 			} else {
// 				$(this).removeClass('active').next().slideUp(speed);
// 			}
// 		});
//
// 	});
// });

// $(window).resize(function() {
//     if($(window).width() < 769) {
//           alert('ssss');
//     }
// });
// $(window).resize(function() {
//     if($(window).width() < 769) {
//       var acc2 = document.getElementsByClassName("anchor_tt");
//       var e;
//       // $('.faq_content ul').hide();
//       $(".faq_content ul:first").show();
//
//       for (e = 0; e < acc2.length; e++) {
//         acc2[e].addEventListener("click", function() {
//           this.classList.toggle("active");
//           var panel2 = $(this).next();
//           panel2.slideToggle();
//         });
//       }
//     }
//     else {
//       $('.faq_content ul').show();
//
//     }
// });

// accordion
$(document).ready(function () {

  if ($(window).width() < 769) {

    var acc2 = document.getElementsByClassName("anchor_tt");
    var i;
    $('.faq_content ul').hide();
    $(".faq_content ul:first").show();

    for (i = 0; i < acc2.length; i++) {
      acc2[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = $(this).next();
        panel.slideToggle();
      });
    }
  } else {
    $('.faq_content ul').show();
  }
});

// tab entry

(function ($) {
  $(document).ready(function () {
    $('#tab001').on('click', function (e) {
      var href = "#tab01";
      $("#tab002").removeClass("active");
      $(this).addClass("active");

      doAnchor(href);
    });
    $('#tab002').on('click', function (e) {
      var href = "#tab02";
      $("#tab001").removeClass("active");
      $(this).addClass("active");

      doAnchor(href);
    });
  });
  $(document).ready(function () {
    $(window).scrollTop(0);
    initAnchor();
  });

  function initAnchor() {
    // check hash
    if (window.location.hash) {
      doAnchor(window.location.hash);
    }
  }

  function doAnchor(target) {
    // $("#tab02").addClass("active");
    if (target == '#tab02') {
      $("#tab0002").addClass("active");
      $("#tab001").attr('style', '');

    }
    if (target == '#tab01') {
      $("#tab0001").addClass("active");
      $("#tab002").attr('style', '');

    }
    $(target).triggerHandler('click');
    $('html, body').animate({
      scrollTop: $(".tab-control").offset().top
    }, 100);

  }
})(jQuery);
// function hashchanged(){
// var hash = location.hash
//   alert(hash);
//   if(hash!=""){
//     jQuery(hash.replace("#","tab02")).trigger("click")
//     $(".tab-content .tab02").addClass("active");
// }
//  // if (hash) {
//  //    $(".tab-content .tab02").addClass("active");
//  // }
//  //
// }


// $(document).ready(function () {
//   var $bxslider_search = $('#slider_01').bxSlider({
//     auto: true,
//     pause: 5000,
//     maxSlides: 1,
//     // slideWidth: 225,
//     easing: 'easeOutExpo',
//     speed: 1000,
//     controls: true,
//     pager: true,
//     pagerType: 'short',
//     // onSlideAfter: function () { $bxslider_search.startAuto(); },
//     onSliderLoad: function (currentIndex) {
//       $('#slider_01').children().eq(currentIndex).addClass('active-slide');
//     },
//     onSlideAfter: function ($slideElement) {
//       $('#slider_01').children().removeClass('active-slide');
//       $slideElement.addClass('active-slide');
//     }
//   });
// });
//--------------gototop-------------------------------
//
// function isSameResource(urlOne, urlTwo) {
//   var fragmentPattern = /#.*$/;
//   var resourceOne = urlOne.replace(fragmentPattern, '');
//   var resourceTwo = urlTwo.replace(fragmentPattern, '');
//   return resourceOne === resourceTwo;
// }
//
// function getFragmentTarget(id) {
//   if (id.slice(0, 1) === '#') {
//     id = id.slice(1);
//   }
//   return document.getElementById(id) || document.querySelector('a[name="' + id + '"]');
// }
// $(document.body).on('click', 'a[href*="#"]:not([href="#"])', function(event) {
//
//   //if (event.isDefaultPrevented()) {
//   //return;
//   //}
//   //if (!isSameResource(location.href, this.href)) {
//   //return;
//   //}
//
//   // console.log("Test");
//
//   event.preventDefault();
//   $('html').removeClass('overflow');
//   $(".btn-nav").removeClass("is-active");
//   $('.menu-header-mobile').removeClass('active');
//   var target = getFragmentTarget(this.hash);
//   // console.log(target, $(target).offset().top - 50);
//   window.location.hash = target.id || target.name;
//
//
//   $('html, body').animate({
//     scrollTop: $(target).offset().top - 50
//   }, {
//     duration: 100,
//     queue: false
//   });
// });

// $(window).on("scroll", function() {
//   $('#about').offset().top;
//   $('#how-to').offset().top;
// });
$(document).ready(function () {
  var $modalClose = $('#popup-overlay');
  $('.modal-upfile').each(function (i) {
    $(this).addClass('wrap' + i);

  });

  $('.modal-upfile').each(function (i) {
    /*$('.wrap' + i).click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('#popup-overlay').css('opacity', '0');
      $('#popup-overlay').css('display', 'table');
      $('#popup-overlay').animate({
        opacity: '1'
      }, 500);
      $modalClose.removeClass('displayNone');
    });*/
  });
});

function openModal(e, imgPreviewFor) {
  var $modalClose = $('#popup-overlay');

  e.preventDefault();
  e.stopPropagation();

  $("#popup-overlay #file-input").attr('data-preview-container', imgPreviewFor);
  $('#popup-overlay').css('opacity', '0');
  $('#popup-overlay').css('display', 'table');
  $('#popup-overlay').animate({
    opacity: '1'
  }, 500);
  $('#popup-closer').click(function () {
    $('#popup-overlay').css('display', 'none');
  });
  $('#popup-wrapper .close').click(function () {
    $('#popup-overlay').css('display', 'none');
  });
  $modalClose.removeClass('displayNone');
}

// $(document).ready(function () {
//   $(".tab-content .tab-item:first").show().addClass("active");
//   $(".btn-slider li").click(function () {
//     $(".btn-slider li").removeClass("active");
//     $(this).addClass("active");
//     $(".tab-content .tab-item").hide();
//     var url = $(this).attr("data-tab");
//     $("#" + url).fadeIn().addClass("active");
//   });
// });

jQuery(document).ready(function ($) {
  $(".tab-content .tab-item:first").addClass("active");
  $(".btn-slider li").click(function () {
    $(".btn-slider li").removeClass("active");
    $(this).addClass("active");
    $(".tab-content .tab-item").removeClass("active");
    var url = $(this).attr("data-tab");
    $("#" + url).fadeIn().addClass("active");
  });
});
$(document).ready(function () {
  // $('.slider-class').slick('setPosition');
  // $(window).resize(function () {
  //   $('.item-slider')[0].slick.refresh();
  // });
  $('.item-slider').on('init', function (event, slick) {
    $(this).append('<div class="slider-info"><p><span id="current-slider">1</span> / <span id="total">' + slick.slideCount + '</span></p></div>');
  });

  $('.item-slider').slick({
    centerMode: true,
    centerPadding: '15%',
    slidesToShow: 1,
    arrows: true,
  });
  $('.item-slider')
    .on('afterChange', function (event, slick, currentSlide, nextSlide) {
      // finally let's do this after changing slides
      $('.slider-info #current-slider').html(currentSlide + 1);
    });

  // $(window).resize(function () {
  //   $('.item-slider')[0].slick.refresh();
  // });
  // $(window).on('resize orientationchange', function () {
  //   $('.item-slider').slick('resize');
  // });
  // $('.item-slider').slick('setPosition');

});
$(document).ready(function () {
  $('.item-slider2').on('init', function (event, slick) {
    $(this).append('<div class="slider-info2"><p><span id="current-slider">1</span> / <span id="total">' + slick.slideCount + '</span></p></div>');
  });

  $('.item-slider2').slick({
    centerMode: true,
    centerPadding: '15%',
    slidesToShow: 1,
    arrows: true,
  });
  $('.item-slider2')
    .on('afterChange', function (event, slick, currentSlide, nextSlide) {

      $('.slider-info2 #current-slider').html(currentSlide + 1);
    });
});

$(document).ready(function () {
  function getHeight(parent, classname, column) {
      var $target = $("." + classname);
      var i = 0;
      var j = 0;
      $(parent).find($target).each(function() {
        if ($(this).length > 0) {
          if (i < column) {
            $(this).addClass(classname + j);
            i++;
          } else {
            i = 1;
            j++;
            $(this).addClass(classname + j);
          }
        }
      })
      $(parent).find($target).css("height", "auto");
      for (var k = 0; k <= j; k++) {
        var maxHeight = 0;
        $("." + classname + k).each(function() {
          var h_text = $(this).outerHeight();
          if (h_text > maxHeight) {
            maxHeight = h_text;
          }
        })
        $("." + classname + k).css("height", maxHeight);
      }
      var i = 0;
      var j = 0;
      $(parent).find($target).each(function() {
        if ($(this).length > 0) {
          if (i < column) {
            $(this).removeClass(classname + j);
            i++;
          } else {
            i = 1;
            j++;
            $(this).removeClass(classname + j);
          }
        }
      })
    }

 	$(window).resize(function() {
 		var viewWidth = $(window).width();
 		if(viewWidth > 768){
			getHeight(".step", "step_content", "4");
 			getHeight(".step", "txt01", "4");

 		}else {
 			getHeight(".step", "step_content", "2");
 		}


 	}).resize();

});

$(function() {
  var ua = window.navigator.userAgent;
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  if (is_ie) {
    $(window).scroll(function() {
      var triggerPos = $('.blk-tab-main').offset().top;

      var scrollY = $(window).scrollTop() + $('.header').outerHeight();

      if (scrollY >= triggerPos) {
        $('.blk-tab-main .ct-tab-main').css({
          position: 'fixed',
          width: '100%',
        });
      } else {
        $('.blk-tab-main .ct-tab-main').css({
          position: '',
          width: '',
        });
      }
    }).scroll();
  }
});
